import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maint',
  templateUrl: './maint.component.html',
  styleUrls: ['./maint.component.css']
})
export class MaintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
