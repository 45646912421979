
<div class="container" style="margin-top:0px;">


                        <!-- LOGO -->    
                        <div class="row" style="padding-top:50px;">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="brand_logo">STREAMS.CC</div>
                            </div>
                        </div>

                        <!-- Image -->
                        <div class="row" style="margin-top:20px;">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <img src="https://vollleycdn.s3.us-west-2.amazonaws.com/mult-site/streamscc/maintenance.png" width="60%" height="100%" />
                            </div>
                        </div>


                        <div class="row" style="margin-top:10px;padding-left:20px;">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="strong_text">Hang on!  We are under maintenance</div>                                
                            </div>
                        </div>

                        <div class="row" style="margin-top:10px;padding-left:20px;">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="light_text">We are working hard to make sure that our site will be available soon</div>                             
                            </div>
                        </div>

                        
</div>



