import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserApiService } from './user.api.service';


@Injectable()
export class UserDataService {

  constructor(
    private http: HttpClient,
    private api: UserApiService    
  ) { }

  pre_registration(n_type:string) {
    return this.api.pre_registration(n_type)    
  }


}