	

<div class="row">
	<div class="col-12" style="text-align:center;">
		<div class="d-flex aligns-items-center copy_text"> &#169; {{nyear}} STREAMS.CC</div>
	</div>
</div>

	

