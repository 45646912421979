import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaintComponent } from './landing/maint/maint.component';
import { FaqComponent } from './landing/faq/faq.component';
import { HeaderComponent } from './landing/header/header.component';
import { FooterComponent } from './landing/footer/footer.component';


/* Coming Soon */
import { FtrComponent } from './landing/coming-soon/ftr/ftr.component';
import { HdrComponent } from './landing/coming-soon/hdr/hdr.component';
import { WaitComponent, NotifyDialog } from './landing/coming-soon/wait/wait.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input'; 
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule} from '@angular/material/select';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule} from '@angular/material/checkbox';

import {MatSnackBarModule} from '@angular/material/snack-bar';



/*
import { MatTableModule} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
*/


/* JWT */
import { JwtModule } from "@auth0/angular-jwt";


/* Services */
import { TokenManager } from './_serviceAPI/token-manager.service';
import { UserDataService } from './_serviceAPI/user.data.service';
import { UserApiService } from './_serviceAPI/user.api.service';


/* Model */
// import { MsgInfo } from './_model/msginfo';




export function tokenGetter() {
  return localStorage.getItem("access_token");
}



@NgModule({
  declarations: [
    AppComponent,
    MaintComponent,
    FaqComponent,
    HeaderComponent,
    FooterComponent,
    FtrComponent,
    HdrComponent,
    WaitComponent,
    NotifyDialog
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["streams.cc"],
        disallowedRoutes: ["http://streams.cc/examplebadroute/"],
      },
      }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSliderModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSnackBarModule
  ],
  providers: [TokenManager, UserApiService, UserDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
