import { Injectable } from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { DeviceDetectorService } from 'ngx-device-detector';

import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();


@Injectable()
export class TokenManager {

    constructor(private _http: HttpClient) {}

    private ftrTokenData:string = 'streams_cc_app_token';   //unique_id

    private store(content:Object) {
        localStorage.setItem(this.ftrTokenData, JSON.stringify(content));
    }


    private retrieve() {
        let storedToken = localStorage.getItem(this.ftrTokenData);
        // let storedToken:string = localStorage.getItem(this.ftrTokenData);
        if(!storedToken) throw 'no token found';
        return storedToken;
    }


    public getTokenHeadersFAKE(n_state:boolean){
                
        // login found get token headers
        let headers = new Headers();
        let headersX = new HttpHeaders();

        if (n_state == true) {
            
            headersX = headersX.set('Access-Control-Allow-Origin', '*')
            .set('X-Auth-Token', '032fff3e-3990-4bbf-b5f1-def133bf1e79')
            .set('X-Requested-By', '28dd533a-0c5c-4825-9c52-c352006bc184')
            .set('Accept', 'application/json');

        } else {

            headersX = headersX.set('Access-Control-Allow-Origin', '*')
            .set('X-Auth-Token', '460318af-f292-4bcb-bc64-f3d2b7743029')
            .set('Accept', 'application/json');

        }

        return headersX;

    }





    public getTokenHeaders(){
        
        let n_state = this.getToken();

        // login found get token headers
        let headers = new Headers();
        let headersX = new HttpHeaders();

        if (n_state) {
            
            headersX = headersX.set('Access-Control-Allow-Origin', '*')
            .set('X-Auth-Token', n_state["user_access_token"])
            .set('X-Requested-By', n_state["user_guid"])
            .set('Accept', 'application/json');

        } else {

            headersX = headersX.set('Access-Control-Allow-Origin', '*')
            .set('X-Auth-Token', '032fff3e-3990-4bbf-b5f1-def133bf1e79')
            .set('Accept', 'application/json');

        }

        return headersX;

    }


    public getTokenVendorString(){
        var h = { 'X-Auth-Token': '032fff3e-3990-4bbf-b5f1-def133bf1e79', 'X-Requested-By':'-no-user-static-vendor', 'Access-Control-Allow-Origin': '*' }
        return h
    }


    public getTokenVendorString1(){
        var h = { 'X-Auth-Token': '032fff3e-3990-4bbf-b5f1-def133bf1e79', 'X-Requested-By':'-no-user-static-vendor', 'Access-Control-Allow-Origin': '*' }
        return h
    }


    


    // UPDATE TOKEN - (if user settings change)
    public updateToken(content:Object) {
        var secret = 'secret';
        // var token = const decodedToken = helper.encodeToken(myRawToken);
        // var token = jwt.encode(content, secret);
        // var token = helper.decodeToken(content)
        var token = "123";
        localStorage.setItem(this.ftrTokenData, token.toString());
    }




    // UPDATE TOKEN Value - (if user settings change)
    public updateTokenValue(n_key:string, n_value:string) {


        try {
            // const decodedToken = helper.decodeToken(myRawToken);
            // const expirationDate = helper.getTokenExpirationDate(myRawToken);
            // const isExpired = helper.isTokenExpired(myRawToken);

            let storedToken:string = localStorage.getItem(this.ftrTokenData)!;            
            // let tokenData = this.getDecodedAccessToken(storedToken); // decode token
            let tokenData  = helper.decodeToken(storedToken);

            // show TokenData Key (make sure your updating the right one)
            tokenData[n_key] = n_value

            // Update Token
            var secret = 'secret';
            // var token = jwt.encode(tokenData, secret);
            var token = helper.decodeToken(tokenData);
            localStorage.setItem(this.ftrTokenData, token.toString());
        }
        catch(err) {
            console.error(err);
        }

    }



    // STORE TOKEN
    public storeToken(content:Object) {
        localStorage.setItem(this.ftrTokenData, content.toString());
    }



    // GET TOKEN
    public getToken() {
        
        let token = null;
        try {
            let storedToken:string = localStorage.getItem(this.ftrTokenData)!;
            
            // let tokenData = this.getDecodedAccessToken(storedToken); // decode token
            let tokenData  = helper.decodeToken(storedToken);

            let storedTokenGet = tokenData;
            token = storedTokenGet;

        }
        catch(err) {
            console.error(err);
        }
        return token;
    }



    public isLoggedIn() {

        // determine if user logged in
        try {
            if (localStorage.getItem(this.ftrTokenData) === null) {
              return false; 
            } else {
              return true;
            }
        }
        catch(err) {
            console.error(err);
        }
        
        return false;
    }



    // DELETE TOKEN ON LOGOUT
    public deleteToken() {
        localStorage.clear();
        localStorage.removeItem(this.ftrTokenData);
    }


}