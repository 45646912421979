import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { TokenManager } from '../_serviceAPI/token-manager.service';

import { TokenManager } from '../_serviceAPI/token-manager.service';

import { Observable } from 'rxjs';

// import { DeviceDetectorService } from 'ngx-device-detector';
// import { Dictionary } from '../_model/dictionary';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;
// const API_URL = "http://localhost:8080"


@Injectable()
export class UserApiService {

  deviceInfo:string = "";

  headersVendor = this.tokenManager.getTokenVendorString()

  // n_env  = "https://api.streams.cc"
  // n_env  = "http://localhost:8080"

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {
      // this.epicFunction()
    }


  /* Pre-Registration */

  public pre_registration(n_email_address:string) {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/pre_registration', {"associated_email":n_email_address,"collection_name":"Global","app_name":"STREAMS.CC","registration_stage":"PRE-REGISTRATION","transaction_guid":"17b6a177-e72b-483a-a98b-c1c240f2276f","appsource":"web"} , { headers: this.tokenManager.getTokenVendorString1()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}