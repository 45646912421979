import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-hdr',
  templateUrl: './hdr.component.html',
  styleUrls: ['./hdr.component.css']
})
export class HdrComponent implements OnInit {

  n_page_dark:boolean = true;

  url_name:string = '';


  constructor(
    private route:ActivatedRoute,
    private router: Router
    ) { }




  ngOnInit(): void {

    /* Determine Incoming Parameter */
    this.url_name = this.router.url;
    console.log(this.url_name);         // routename

    
    /* Apply Class Based on Page */


  }

}
