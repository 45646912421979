import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WaitComponent } from './landing/coming-soon/wait/wait.component';
import { FaqComponent } from './landing/faq/faq.component';
import { MaintComponent } from './landing/maint/maint.component';




const routes: Routes = [  
  
  { path: '', component: WaitComponent},
  { path: 'faq', component: FaqComponent}
  /* { path: '', component: MaintComponent} */
  
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
