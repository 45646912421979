<div class="container-xl">

<!-- https://getbootstrap.com/docs/4.1/components/collapse/  -->

<app-hdr></app-hdr>


	<div class="row" style="margin-top:50px;margin-bottom:50px;text-align: center;">
		<div class="col-sm-12 col-md-12 col-lg-12">
			

			<div class="faq_small_title">FAQs</div>
			<div class="faq_large_title">Frequently asked questions</div>
			<div class="faq_have_questions">Have questions?  Were here to help</div>

		</div>
	</div>



  <div class="row">

    
    <div class="col-sm-12 col-md-12 col-lg-12">

    	<div class="accordion" id="accordionExample">
		  <div class="card">
		    <div class="card-header" id="headingOne">
		      <h5 class="mb-0">
		        <button class="btn btn-link faq-title-txt" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
		          What is Streams.cc
		        </button>
		      </h5>
		    </div>

		    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
		      <div class="card-body">
		        Streams.cc and the Streams.cc App help users to find content across multiple platforms in multiple jurisdictions as well as find future unrelease content by dates.
		      </div>
		    </div>
		  </div>
		  <div class="card">
		    <div class="card-header" id="headingTwo">
		      <h5 class="mb-0">
		        <button class="btn btn-link faq-title-txt collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
		          When will Streams.cc be available ?
		        </button>
		      </h5>
		    </div>
		    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
		      <div class="card-body">
		        We are currently in private beta and will be making more publicly accessible accounts available to the public towards the end of this year and the upcoming new year.
		      </div>
		    </div>
		  </div>
		  <div class="card">
		    <div class="card-header" id="headingThree">
		      <h5 class="mb-0">
		        <button class="btn btn-link faq-title-txt collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
		          Will Streams.cc offer free streamable content ?
		        </button>
		      </h5>
		    </div>
		    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
		      <div class="card-body">
		        We will be directing a large effort of our time towards helping user find content that is locally viable as well as content that does not require a paid service.  This will be done in conjunction with content on major platforms.
		      </div>
		    </div>
		  </div>
		</div>



    </div>
            

  </div>


</div>





