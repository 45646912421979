
<nav class="navbar navbar-light" style="margin-top:0px;">
  <div class="container-fluid" style="margin-top:50px;">

    <a class="navbar-brand">
    	<div [ngClass]="url_name === '/' ? 'brand_logo show_light' : 'brand_logo'" routerLink="/">STREAMS.CC</div> <span class="brand_beta">&nbsp;&nbsp;(beta)</span></a>  
    
    <form class="d-flex">
      <button [ngClass]="url_name === '/' ? 'btn btn-outline-light me-1' : 'btn btn-outline-dark me-1'" routerLink="/faq">FAQ</button>
    </form>

  </div>
</nav>
